<template>
  <span ref="target">
    <b-link href="#" @click="$emit('toggle')">
      <b-icon-chevron-up v-if="row.detailsShowing"></b-icon-chevron-up>
      <b-icon-chevron-down v-else></b-icon-chevron-down>
    </b-link>
    <b-tooltip
      v-if="computedTooltip"
      :target="() => $refs['target']"
      triggers="hover"
      variant="primary"
    >
      <slot>D&eacute;tails</slot>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: "ToggleRowComponent",
  props: ["row", "tooltip"],
  computed: {
    computedTooltip() {
      return this.tooltip !== false && this.tooltip !== "false";
    },
  },
};
</script>
