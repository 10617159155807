<template>
  <b-tooltip :target="target" triggers="hover" variant="primary">
    <template
      v-if="quantitePrevisionnelle != null && quantiteMoissonnee != null"
    >
      Pr&eacute;visionnel :
      <MontantComponent :value="quantitePrevisionnelle"></MontantComponent>
      t <br />
      + Moissonn&eacute; :
      <MontantComponent :value="quantiteMoissonnee"></MontantComponent>
      t <br />
      --- <br />
      = Produit :
      <MontantComponent :value="quantiteProduite"></MontantComponent>
      t <br />
    </template>
    <template v-else>
      <template v-if="quantiteMoissonnee == null">
        Pr&eacute;visionnel :
      </template>
      <template v-else>Moissonn&eacute; :</template>
      <MontantComponent :value="quantiteProduite"></MontantComponent>
      t <br />
    </template>
    - Autoconsomm&eacute; :
    <MontantComponent :value="quantiteAutoconsommee"></MontantComponent>
    t <br />
    --- <br />
    = A collecter :
    <MontantComponent
      :value="quantiteProduite - quantiteAutoconsommee"
    ></MontantComponent>
    t
  </b-tooltip>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
export default {
  name: "QuantiteCollecteTooltip",
  components: { MontantComponent },
  props: [
    "target",
    "quantitePrevisionnelle",
    "quantiteMoissonnee",
    "quantiteAutoconsommee",
  ],
  computed: {
    quantiteProduite() {
      return (
        (this.quantitePrevisionnelle ?? 0.0) + (this.quantiteMoissonnee ?? 0.0)
      );
    },
    quantiteCollecte() {
      return (
        (this.quantiteProduite ?? 0.0) - (this.quantiteAutoconsommee ?? 0.0)
      );
    },
  },
};
</script>
